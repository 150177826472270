
import { computed, defineComponent, ref, watchEffect, PropType } from 'vue'
import { useStore } from '@/utils/composables'

import { MyndshftPanel } from '@myndshft/panel'
import ModifiedbyTooltip from '@/components/worklist/shared/modifiedby-tooltip.vue'
import PortalCreate from './create.vue'

import { isValidUrl } from '../../../utils/helpers'

import { Alert, toast } from '@/dependencies/sweet-alert'

import { deletePayerItemError } from '@/utils/message'
import { IContactPayerPortal, IPayerPlan } from '@server/api/models/fe/payer'
import { ContactMethodType } from '@server/api/models/fe/shared'

interface IHeader {
	text: string
	value: string
	width?: string
}

export default defineComponent({
	components: {
		MyndshftPanel,
		[PortalCreate.name]: PortalCreate,
		[ModifiedbyTooltip.name]: ModifiedbyTooltip,
	},
	props: {
		payerPlans: Array as PropType<IPayerPlan[]>,
		createPortal: { type: Function, required: true },
		updatePortal: { type: Function, required: true },
		deletePortal: { type: Function, required: true },
		resetPayer: { type: Function, required: true },
		isSaving: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const store = useStore()
		const state = ref(store.state.payer)
		const portals = ref(state.value.contactMethods.portals)

		watchEffect(() => {
			portals.value = store.state.payer.contactMethods.portals
		})

		const headers: IHeader[] = [
			{ text: 'Name', value: 'name', width: '200px' },
			{ text: 'URL', value: 'url' },
			{ text: 'Plan', value: 'plan.name', width: '400px' },
			{ text: '', value: 'save', width: '75px' },
		]
		const staticHeaders: IHeader[] = [
			{ text: 'Name', value: 'name', width: '200px' },
			{ text: 'URL', value: 'url' },
			{ text: 'Plan', value: 'plan.name', width: '400px' },
		]

		const isLoading = ref(store.state.payer.loading.portals)
		watchEffect(() => {
			isLoading.value = store.state.payer.loading.portals
		})

		const save = async (item: IContactPayerPortal) => {
			if (isValidUrl(item.url)) {
				try {
					await props.updatePortal(item)
					toast({
						type: Alert.SUCCESS,
						title: 'Successfully updated the portal',
					})
				} catch (error) {
					toast({ type: Alert.ERROR, title: 'Failed to update portal' })
					props.resetPayer()
				}
			} else {
				toast({ type: Alert.ERROR, title: 'URL provided is not valid' })
				props.resetPayer()
			}
		}

		const portalData: IContactPayerPortal = {
			id: '',
			name: '',
			plan: {
				id: '',
				name: '',
				uniqueId: '',
			},
			type: ContactMethodType.PORTAL,
			url: '',
			_meta: {
				lastModified: '',
				lastModifiedBy: '',
			},
		}
		const newPortal = ref<IContactPayerPortal>({ ...portalData })
		const resetNewPortal = () => {
			newPortal.value = { ...portalData }
		}

		const createNewPortal = async (portal: IContactPayerPortal) => {
			try {
				await props.createPortal(portal)
				toast({ type: Alert.SUCCESS, title: 'Successfully created the portal' })
				newPortal.value = { ...portalData }
			} catch (error) {
				toast({ type: Alert.ERROR, title: 'Failed to create portal' })
			}
		}

		const onDelete = async (portal: IContactPayerPortal) => {
			try {
				await props.deletePortal(portal)
				toast({ type: Alert.SUCCESS, title: 'Successfully deleted the portal' })
			} catch (error) {
				if (error) {
					const message = `Failed to delete portal. This contact method has the following associations: ${deletePayerItemError(
						error as string
					)}`
					toast({ type: Alert.ERROR, title: message, timer: 5000 })
				} else {
					toast({ type: Alert.ERROR, title: 'Failed to delete portal' })
				}
			}
		}

		const totalCount = computed(() => {
			return portals.value.length
		})

		return {
			portals,
			headers,
			staticHeaders,
			createNewPortal,
			newPortal,
			onDelete,
			isLoading,
			resetNewPortal,
			totalCount,
			save,
		}
	},
})
