
import { defineComponent, ref, SetupContext } from 'vue'
import { IContactPayerPortal } from '@server/api/models/fe/payer'

export default defineComponent({
	name: 'portal-create',
	props: {
		payerPlans: Array,
		portal: Object,
		isSaving: {
			type: Boolean,
			default: false,
		},
	},
	setup(_, context: SetupContext) {
		const observer: any = ref(null)
		const create = (portal: IContactPayerPortal) => {
			observer.value.reset()
			context.emit('create', portal)
		}

		const reset = () => {
			observer.value.reset()
			context.emit('reset')
		}

		return {
			create,
			reset,
			observer,
		}
	},
})
