
import { defineComponent, ref, watchEffect, onMounted, onUnmounted } from 'vue'
import { useStore, useRouter } from '@/utils/composables'
import { Alert, toast } from '@/dependencies/sweet-alert'

import Fax from '@/components/payer/fax/fax.vue'
import Portal from '@/components/payer/portal/portal.vue'
import Phone from '@/components/payer/phone/phone.vue'
import Guideline from '@/components/payer/guideline/guideline.vue'
import PayerConfiguration from '@/components/payer/payer-configuration.vue'
import PaRecords from '@/components/payer/pa-records/pa-records.vue'
import PlanAssociations from '@/components/payer/planAssociations/planAssociations.vue'

import { PayerUniqueID } from '@myndshft/types-mc-api'
import {
	IContactPayerFax,
	IContactPayerPhone,
	IContactPayerPortal,
	IPayerDetails,
} from '@server/api/models/fe/payer'
import { IGuideline } from '@server/api/models/fe/shared'

import { fetchPayerPlanAssociations } from '@/service/planAssociation.http'

export default defineComponent({
	props: {},
	components: {
		Fax,
		Portal,
		Phone,
		Guideline,
		PayerConfiguration,
		PaRecords,
		PlanAssociations,
	},
	setup() {
		const router = useRouter()
		const store = useStore()
		const state = ref(store.state.payer)

		const id = (router.currentRoute.params.id as PayerUniqueID) || ''
		const payerPlanAssociations = ref([])
		const isLoading = ref(true)
		const title = 'Add or update payer configuration'

		const isSavingPortal = ref(false)
		const isSavingPhone = ref(false)
		const isSavingFax = ref(false)
		const isSavingGuideline = ref(false)
		const isSaving = ref(false)

		watchEffect(() => {
			state.value = store.state.payer
		})

		onMounted(() => {
			getPayer()
		})

		onUnmounted(() => {
			resetPayer()
		})

		const updateConfig = async (config: IPayerDetails) => {
			try {
				await updatePayer(config)
				getPayer()
				toast({ type: Alert.SUCCESS, title: 'Successfully updated the payer' })
			} catch (error) {
				toast({ type: Alert.ERROR, title: 'Failed to update payer' })
			}
		}

		function getPayer() {
			store.dispatch('payer/getPlans')
			store
				.dispatch('payer/getPayer', id)
				.then(() => {
					fetchPayerPlanAssociations(id)
						.then((res: any) => {
							payerPlanAssociations.value = res
						})
						.catch(error => {
							console.warn(error)
							toast({
								type: Alert.ERROR,
								title: 'Failed to retrieve payer plan associations',
							})
						})
				})
				.catch(err => {
					console.warn(err)
					toast({ type: Alert.ERROR, title: 'Failed to retrieve payer' })
				})
				.finally(() => {
					isLoading.value = false
				})
		}

		const resetPayer = () => {
			store.dispatch('payer/reset').catch(err => {
				console.warn(err)
				toast({ type: Alert.ERROR, title: 'Failed to reset payer' })
			})
		}

		const updatePayer = async (payerConfig: IPayerDetails) => {
			isSaving.value = true
			await store.dispatch('payer/updatePayer', {
				id: payerConfig.id,
				config: payerConfig,
			})
			isSaving.value = false
		}

		const createPortal = async (portal: IContactPayerPortal) => {
			isSavingPortal.value = true
			await store.dispatch('payer/createPortal', {
				uuid: state.value.payer.id,
				portal,
			})
			getPayer()
			isSavingPortal.value = false
		}

		const updatePortal = async (portal: IContactPayerPortal) => {
			isSavingPortal.value = true
			await store.dispatch('payer/updatePortal', {
				uuid: state.value.payer.id,
				portal,
			})
			getPayer()
			isSavingPortal.value = false
		}

		const deletePortal = async (portal: IContactPayerPortal) => {
			await store.dispatch('payer/deletePortal', {
				uuid: state.value.payer.id,
				portal,
			})
			getPayer()
		}

		const createFax = async (fax: IContactPayerFax) => {
			isSavingFax.value = true
			await store.dispatch('payer/createFax', {
				uuid: state.value.payer.id,
				fax,
			})
			getPayer()
			isSavingFax.value = false
		}

		const updateFax = async (fax: IContactPayerFax) => {
			isSavingFax.value = true
			await store.dispatch('payer/updateFax', {
				uuid: state.value.payer.id,
				fax,
			})
			getPayer()
			isSavingFax.value = false
		}

		const deleteFax = async (fax: IContactPayerFax) => {
			await store.dispatch('payer/deleteFax', {
				uuid: state.value.payer.id,
				fax,
			})
			getPayer()
		}

		const createPhone = async (phone: IContactPayerPhone) => {
			isSavingPhone.value = true
			await store.dispatch('payer/createPhone', {
				uuid: state.value.payer.id,
				phone,
			})
			getPayer()
			isSavingPhone.value = false
		}

		const updatePhone = async (phone: IContactPayerPhone) => {
			isSavingPhone.value = true
			await store.dispatch('payer/updatePhone', {
				uuid: state.value.payer.id,
				phone,
			})
			getPayer()
			isSavingPhone.value = false
		}

		const deletePhone = async (phone: IContactPayerPhone) => {
			await store.dispatch('payer/deletePhone', {
				uuid: state.value.payer.id,
				phone,
			})
			getPayer()
		}

		const createGuideline = async (guideline: IGuideline) => {
			isSavingGuideline.value = true
			await store.dispatch('payer/createGuideline', {
				uuid: state.value.payer.id,
				guideline,
			})
			getPayer()
			isSavingGuideline.value = false
		}

		const updateGuideline = async (guideline: IGuideline) => {
			isSavingGuideline.value = true
			await store.dispatch('payer/updateGuideline', {
				uuid: state.value.payer.id,
				guideline,
			})
			getPayer()
			isSavingGuideline.value = false
		}

		const deleteGuideline = async (guideline: IGuideline) => {
			await store.dispatch('payer/deleteGuideline', {
				uuid: state.value.payer.id,
				guideline,
			})
			getPayer()
		}

		return {
			id,
			getPayer,
			updateConfig,
			createPortal,
			updatePortal,
			deletePortal,
			createFax,
			updateFax,
			deleteFax,
			createPhone,
			updatePhone,
			deletePhone,
			createGuideline,
			updateGuideline,
			deleteGuideline,
			state,
			payerPlanAssociations,
			isLoading,
			title,
			isSavingPortal,
			isSavingPhone,
			isSavingFax,
			isSavingGuideline,
			isSaving,
		}
	},
})
