
import { defineComponent, ref, SetupContext, PropType } from 'vue'

import { useFormatting } from '@/dependencies/formatting'
import { IContactPayerPhone, IPayerPlan } from '@server/api/models/fe/payer'

export default defineComponent({
	name: 'phone-create',
	props: {
		payerPlans: Array as PropType<IPayerPlan[]>,
		phone: { type: Object as PropType<IContactPayerPhone>, required: true },
		isSaving: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, context: SetupContext) {
		const observer: any = ref(null)
		const create = (phone: IContactPayerPhone) => {
			observer.value.reset()
			context.emit('create', phone)
		}

		const reset = () => {
			observer.value.reset()
			context.emit('reset')
		}

		const onPhoneNumber = (phoneNumber: string) => {
			const { toPhoneNumber } = useFormatting()
			props.phone.number = toPhoneNumber(phoneNumber)
		}

		return {
			create,
			reset,
			onPhoneNumber,
			observer,
		}
	},
})
