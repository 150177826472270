import { render, staticRenderFns } from "./pa-requirement-set.vue?vue&type=template&id=019f5718&scoped=true"
import script from "./pa-requirement-set.vue?vue&type=script&lang=ts"
export * from "./pa-requirement-set.vue?vue&type=script&lang=ts"
import style0 from "./pa-requirement-set.vue?vue&type=style&index=0&id=019f5718&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "019f5718",
  null
  
)

export default component.exports