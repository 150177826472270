import { render, staticRenderFns } from "./payer-configuration.vue?vue&type=template&id=2f2d8ce3&scoped=true"
import script from "./payer-configuration.vue?vue&type=script&lang=ts"
export * from "./payer-configuration.vue?vue&type=script&lang=ts"
import style0 from "./payer-configuration.vue?vue&type=style&index=0&id=2f2d8ce3&prod&scoped=true&lang=scss"
import style1 from "./payer-configuration.vue?vue&type=style&index=1&id=2f2d8ce3&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f2d8ce3",
  null
  
)

export default component.exports