
import { defineComponent, ref, SetupContext } from 'vue'
import { IGuideline } from '@server/api/models/fe/shared'

export default defineComponent({
	name: 'guideline-create',
	props: {
		payerPlans: Array,
		guideline: Object,
		isSaving: {
			type: Boolean,
			default: false,
		},
	},
	setup(_, context: SetupContext) {
		const observer: any = ref(null)
		const create = (guideline: IGuideline) => {
			observer.value.reset()
			context.emit('create', guideline)
		}

		const reset = () => {
			observer.value.reset()
			context.emit('reset')
		}

		return {
			create,
			reset,
			observer,
		}
	},
})
