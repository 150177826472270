
import { defineComponent } from 'vue'

import Payer from '@/components/payer/payer.vue'

export default defineComponent({
	components: {
		Payer,
	},
	setup() {
		return {}
	},
})
