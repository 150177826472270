
import { computed, defineComponent, ref, watchEffect, PropType } from 'vue'
import { useStore } from '@/utils/composables'

import { MyndshftPanel } from '@myndshft/panel'
import ModifiedbyTooltip from '@/components/worklist/shared/modifiedby-tooltip.vue'
import GuidelineCreate from './create.vue'

import { Alert, toast } from '@/dependencies/sweet-alert'

import { deletePayerItemError } from '@/utils/message'
import { isValidUrl } from '@/utils/helpers'
import { IPayerPlan } from '@server/api/models/fe/payer'
import { IGuideline } from '@server/api/models/fe/shared'
import { AssetType } from '@myndshft/types-mc-api'

interface IHeader {
	text: string
	value: string
	width?: string
}

export default defineComponent({
	components: {
		MyndshftPanel,
		[GuidelineCreate.name]: GuidelineCreate,
		[ModifiedbyTooltip.name]: ModifiedbyTooltip,
	},
	props: {
		payerPlans: Array as PropType<IPayerPlan[]>,
		createGuideline: { type: Function, required: true },
		updateGuideline: { type: Function, required: true },
		deleteGuideline: { type: Function, required: true },
		resetPayer: { type: Function, required: true },
		isSaving: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const store = useStore()
		const state = ref(store.state.payer)
		const guidelines = ref(state.value.assets)

		watchEffect(() => {
			guidelines.value = store.state.payer.assets
		})

		const headers: IHeader[] = [
			{ text: 'Name', value: 'name' },
			{ text: 'URL', value: 'url' },
			{ text: '', value: 'save', width: '75px' },
		]
		const staticHeaders: IHeader[] = [
			{ text: 'Name', value: 'name' },
			{ text: 'URL', value: 'url' },
		]

		const isLoading = ref(store.state.payer.loading.guidelinees)
		watchEffect(() => {
			isLoading.value = store.state.payer.loading.guidelinees
		})

		const save = async (item: IGuideline) => {
			if (isValidUrl(item.url)) {
				try {
					await props.updateGuideline(item)
					toast({
						type: Alert.SUCCESS,
						title: 'Successfully updated the guideline',
					})
				} catch (error) {
					toast({
						type: Alert.ERROR,
						title: 'Failed to update guideline',
					})
					props.resetPayer()
				}
			} else {
				toast({ type: Alert.ERROR, title: 'URL provided is not valid' })
				props.resetPayer()
			}
		}

		const guidelineData: IGuideline = {
			id: '',
			name: '',
			type: AssetType.GUIDELINE,
			url: '',
			_meta: {
				lastModified: '',
				lastModifiedBy: '',
			},
		}
		const newGuideline = ref<IGuideline>({ ...guidelineData })
		const resetNewGuideline = () => {
			newGuideline.value = { ...guidelineData }
		}

		const createNewGuideline = async (guideline: IGuideline) => {
			try {
				await props.createGuideline(guideline)
				toast({
					type: Alert.SUCCESS,
					title: 'Successfully created the guideline',
				})
				newGuideline.value = { ...guidelineData }
			} catch (error) {
				toast({ type: Alert.ERROR, title: 'Failed to create guideline' })
			}
		}

		const onDelete = async (guideline: IGuideline) => {
			try {
				await props.deleteGuideline(guideline)
				toast({
					type: Alert.SUCCESS,
					title: 'Successfully deleted the guideline',
				})
			} catch (error) {
				if (error) {
					const message = `Failed to delete guideline. This guideline has the following associations: ${deletePayerItemError(
						error as string
					)}`
					toast({ type: Alert.ERROR, title: message, timer: 5000 })
				} else {
					toast({
						type: Alert.ERROR,
						title: 'Failed to delete guideline',
					})
				}
			}
		}

		const totalCount = computed(() => {
			return guidelines.value.length
		})

		return {
			guidelines,
			headers,
			staticHeaders,
			createNewGuideline,
			newGuideline,
			onDelete,
			isLoading,
			resetNewGuideline,
			totalCount,
			save,
		}
	},
})
