
import { computed, defineComponent, ref, watch, watchEffect } from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'
import { useStore } from '@/utils/composables'
import { MyndshftPanel } from '@myndshft/panel'

import { useFormatting } from '@/dependencies/formatting'
import { fetchForms } from '@/service/worklist.http'

import {
	NetworkAuthRequirement,
	NetworkAuthRequirementName,
} from '@server/api/models/fe/shared'
import { IForm } from '@/models/worklists/outcome'
import { states } from '@/components/shared/configuration/states'
import { IAuthOption } from '@/components/shared/form/radio-input.vue'
import { IPayerTransaction } from '@server/api/models/fe/payer'

import LastModified from '@/components/shared/last-modified/last-modified.vue'
import ConfirmationDialog from '@/components/shared/dialog/confirmation-dialog.vue'
import PayerTransactions from '@/components/payer/payer-transactions.vue'

export default defineComponent({
	props: {
		updateConfig: { type: Function, required: true },
		title: String,
		isSaving: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		MyndshftPanel,
		LastModified,
		ConfirmationDialog,
		PayerTransactions,
	},
	setup(props) {
		const selectedForm = ref<IForm | null>(null)
		const loadingOptions = ref<any>(null)
		const formOptions = ref<IForm[]>([])
		const store = useStore()
		const config = ref(store.state.payer.payer)
		const showSaveDialog = ref(false)

		const updateTransactions = (updatedTransactions: IPayerTransaction[]) => {
			config.value.transactions = updatedTransactions
		}

		const authOptions: any = computed<IAuthOption[]>(() => getAuthOptions())

		const getAuthOptions = (): IAuthOption[] => {
			const options: IAuthOption[] = [
				{
					label: NetworkAuthRequirementName.get(
						NetworkAuthRequirement.NO_AUTH
					) as NetworkAuthRequirement,
					inputValue: NetworkAuthRequirement.NO_AUTH,
				},
				{
					label: NetworkAuthRequirementName.get(
						NetworkAuthRequirement.OON
					) as NetworkAuthRequirement,
					inputValue: NetworkAuthRequirement.OON,
				},
				{
					label: NetworkAuthRequirementName.get(
						NetworkAuthRequirement.OON_PA
					) as NetworkAuthRequirement,
					inputValue: NetworkAuthRequirement.OON_PA,
				},
			]
			return options.map(option => {
				option.disabled = false
				return option
			})
		}

		const onSave = async () => {
			props.updateConfig(
				{
					...config.value,
					authSubmissionOONForm: selectedForm.value?.id || null,
				},
				config.value.transactions
			)
			showSaveDialog.value = false
		}

		const isRequired = computed(() => {
			// If there is a value, user is filling out form so require validation
			return Object.values(config.value.address).some(Boolean)
		})

		const onZipCode = (value: string) => {
			const { toZipCode } = useFormatting()
			config.value.address.zip = toZipCode(value)
		}

		const loadOptions = async (search: string | null, id: string | null) => {
			const formOptionsValues = ((await fetchForms(search, id ? [id] : null)) ||
				[]) as IForm[]
			if (loadingOptions.value) {
				clearTimeout(loadingOptions.value)
				loadingOptions.value = null
			}

			formOptions.value = formOptionsValues
			return formOptionsValues
		}

		const onPaSearch = (search?: string | null) => {
			if (loadingOptions.value) {
				clearTimeout(loadingOptions.value)
			}
			loadingOptions.value = setTimeout(
				() => loadOptions(search || null, null),
				500
			)
		}

		const fetchFormsById = (oonFormId?: string | null) => {
			loadOptions(null, oonFormId || null).then(forms => {
				selectedForm.value = oonFormId
					? forms.find(form => form.id === oonFormId) || {
							id: oonFormId,
							name: '',
					  }
					: null
			})
		}

		const canSaveTransactions = computed(() => {
			const canSave = store.getters['payer/canSaveTransactions']
			if (!canSave) {
				toast({
					type: Alert.INFO,
					title:
						'Using Prior Auth Submission also requires Prior Auth Requirements.',
					timer: 5000,
				})
			}
			return canSave
		})

		watchEffect(() => {
			config.value = store.state.payer.payer
			config.value.transactions = store.state.payer.transactions
			config.value.authSubmissionOONForm =
				store.state.payer.authSubmissionOONForm
		})

		watch(
			() => config.value.status,
			cStatus => {
				if (cStatus === 'INACTIVE') {
					const inactiveTransactions =
						config.value.transactions?.map((t: any) => {
							t.isActive = false
							return t
						}) || []
					config.value.transactions = inactiveTransactions
				}
			}
		)

		watch(
			() => store.getters['payer/getAuthSubmissionOONForm'],
			(oonFormId?: any) => {
				if (oonFormId) {
					fetchFormsById(oonFormId)
				} else if (!formOptions.value.length) {
					fetchFormsById(null)
				}
			}
		)

		return {
			states,
			configTitle: props.title,
			selectedForm,
			loadingOptions,
			formOptions,
			config,
			isRequired,
			authOptions,
			canSaveTransactions,
			showSaveDialog,
			onSave,
			onZipCode,
			onPaSearch,
			updateTransactions,
		}
	},
})
