
import { defineComponent, onMounted, ref, watch, PropType } from 'vue'

import { MyndshftPanel } from '@myndshft/panel'

import {
	fetchPayerPlanAssociations,
	updatePublishedPayerPlanAssociation,
} from '@/service/planAssociation.http'
import { IPublishedPlanAssociation } from '@server/api/models/fe/planAssociation'
import { IPayerPlan } from '@server/api/models/fe/payer'

export default defineComponent({
	name: 'plan-associations',
	components: {
		[MyndshftPanel.name]: MyndshftPanel,
	},
	props: {
		payerId: { type: String, required: true },
		payerPlans: { type: Array as PropType<IPayerPlan[]>, default: () => [] },
		payerName: String,
	},
	setup(props) {
		const data = ref<IPublishedPlanAssociation[]>([])
		const headers = ref([
			{
				value: 'payer.name',
				text: 'Payer',
			},
			{
				value: 'name',
				text: 'Plan Name',
			},
			{
				value: 'planType.name',
				text: 'Plan Type',
			},
		])
		const inputText = ref('')

		onMounted(() => {
			fetchPayerPlanAssociations(props.payerId).then(results => {
				data.value = results.map((association: IPublishedPlanAssociation) => {
					association.planType.name = props.payerPlans.find(
						plan => plan.id === association.planType.id
					)?.name

					association.payer.name = props.payerName
					return association as IPublishedPlanAssociation
				})
			})
			data.value = []
		})

		watch(
			() => props.payerName,
			() => {
				data.value = data.value.map(association => {
					association.payer.name = props.payerName
					return association
				})
			}
		)

		const open = (item: any) => {
			if (item?.planType?.name) {
				inputText.value = item.planType.name
			}
		}

		const save = (item: any) => {
			updatePublishedPayerPlanAssociation(item.payer.id, item)
		}

		const handleInput = (val: string) => {
			inputText.value = val
		}

		return {
			headers,
			data,
			open,
			save,
			handleInput,
			inputText,
		}
	},
})
