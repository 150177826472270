
import {
	computed,
	defineComponent,
	ref,
	watch,
	onBeforeMount,
	PropType,
	watchEffect,
} from 'vue'

import { IPriorAuthRecord } from '@server/api/models/fe/payer'
import {
	IRequirement,
	IRequirementSet,
} from '@server/api/models/fe/requirements'
import {
	AuthRequirementName,
	ContactMethodType,
	FieldOperation,
	FieldOperationName,
	FieldType,
	FieldTypeName,
	IItemOutcome,
	IPlaceOfService,
} from '@server/api/models/fe/shared'
import { ICategory } from '@server/api/models/fe/worklist'
import { IPublishedPlanAssociation } from '@server/api/models/fe/planAssociation'
import { IForm, ISubmissionConfiguration } from '@/models/worklists/outcome'

import { fetchPaRequirementSet } from '@/service/requirements.http'
import {
	fetchForms,
	fetchSubmissionConfigurations,
} from '@/service/worklist.http'
import { fetchPlacesOfService } from '@/service/shared.http'

import { getStateNamesByCodes } from '@/utils/helpers'

export default defineComponent({
	name: 'pa-requirement-set',
	props: {
		item: Object as PropType<IPriorAuthRecord>,
		payerId: String,
		payerPlanAssociations: {
			type: Array as PropType<IPublishedPlanAssociation[]>,
			default: () => [],
		},
	},
	setup(props) {
		const isLoading = ref(true)
		const requirements = ref<IRequirement[]>([])
		const formOptions = ref<IForm[]>([])
		const submissionConfigurationOptions = ref<ISubmissionConfiguration[]>([])
		const hasDefaultRequirements = ref(false)
		const fieldTypeName = FieldTypeName
		const authRequirementName = AuthRequirementName
		const contactMethodType = ContactMethodType
		const formIds = computed<string[]>(() =>
			requirements.value?.map(
				(req: any) => req?.outcome?.authSubmissionFormId || ''
			)
		)

		const submissionConfigurationIds = computed<string[]>(() =>
			requirements.value?.map(
				(req: any) => req?.outcome?.submissionConfigurationId || ''
			)
		)
		const posOptions = ref<IPlaceOfService[]>([])

		const getParameters = (field: any) => {
			const parameters = field.parameters

			switch (field.type) {
				case FieldType.PROCEDURE_PLACE_OF_SERVICE: {
					return parameters.codes
						.map((code: string) => {
							const description = posOptions.value.find(
								posCode => posCode.code === code
							)?.description
							return `${code}-${description}`
						})
						.join(', ')
				}
				case FieldType.PROCEDURE_PRICE: {
					const operation = FieldOperationName.get(parameters.operation)
					const high = parameters.high
					const low = parameters.low

					let price = ''
					if (parameters.operation === FieldOperation.BETWEEN) {
						price = `${operation} $${low} and $${high}`
					} else {
						price = `${operation} $${low}`
					}
					return price.toLowerCase()
				}
				case FieldType.PATIENT_STATE: {
					const states = getStateNamesByCodes(parameters.state)

					return states.join(', ')
				}
				case FieldType.PROCEDURE_CODE: {
					return parameters.modifiers.join(', ')
				}
				case FieldType.DIAGNOSIS: {
					const categories = parameters.categories.map(
						(category: ICategory) => category.name
					)
					const codes = parameters.codes
					return `${categories.concat(codes).join(', ')}`
				}
				case FieldType.PLAN_NAME: {
					const planIds = parameters.plans.map(
						(plan: { id: string }) => plan.id
					)
					const planNames = planIds.map((planId: string) => {
						const plan = props.payerPlanAssociations.filter(
							(association: { id: string }) => association.id === planId
						)

						return plan[0]?.name || ''
					})
					return planNames.join(', ')
				}
				case FieldType.PROCEDURE_QUANTITY: {
					const operation = FieldOperationName.get(parameters.operation)
					const high = parameters.high
					const low = parameters.low

					let quantity = ''
					if (parameters.operation === FieldOperation.BETWEEN) {
						quantity = `${operation} ${low} and ${high}`
					} else {
						quantity = `${operation} ${low}`
					}
					return quantity.toLowerCase()
				}
			}
		}

		const getContactMethods = (
			type: ContactMethodType,
			outcome: IItemOutcome
		) => {
			switch (type) {
				case ContactMethodType.PHONE: {
					return outcome.contactMethods.phone?.number
				}
				case ContactMethodType.PORTAL: {
					return outcome.contactMethods.portal?.name
				}
				case ContactMethodType.FAX: {
					return outcome.contactMethods.faxes
				}
			}
		}

		const removeDefaultRequirement = (responseRequirements: IRequirement[]) => {
			const index = responseRequirements.findIndex(item => {
				return item.fields
					? item.fields.find(field => field.type === FieldType.MATCH_EVERYTHING)
					: -1
			})

			if (index > -1) {
				responseRequirements.splice(index, 1)
			}

			return responseRequirements
		}

		const getFormName = (id: string): string => {
			return formOptions.value?.find(opt => opt.id === id)?.name || ''
		}

		const getSubmissionConfigurationName = (id: string): string => {
			return (
				submissionConfigurationOptions.value?.find(opt => opt.id === id)
					?.name || ''
			)
		}

		watch(formIds, ids => {
			const validIds = ids.filter(id => !!id)
			if (validIds.length > 0) {
				fetchForms(null, validIds).then(res => {
					formOptions.value = res
				})
			}
		})

		watch(submissionConfigurationIds, ids => {
			const validIds = ids.filter(id => !!id)
			if (validIds.length > 0) {
				fetchSubmissionConfigurations(null, validIds).then(res => {
					submissionConfigurationOptions.value = res
				})
			}
		})

		watchEffect(() => {
			if (props.item?.requirementSetId) {
				isLoading.value = true
				fetchPaRequirementSet(props.item?.requirementSetId)
					.then((set: IRequirementSet) => {
						hasDefaultRequirements.value = set.hasDefaultRequirement || false
						requirements.value = removeDefaultRequirement(set.requirements)
					})
					.finally(() => {
						isLoading.value = false
					})
			}
		})

		onBeforeMount(async () => {
			posOptions.value = await fetchPlacesOfService()
		})

		return {
			isLoading,
			requirements,
			hasDefaultRequirements,
			fieldTypeName,
			authRequirementName,
			contactMethodType,

			getFormName,
			getSubmissionConfigurationName,
			getParameters,
			getContactMethods,
		}
	},
})
