
import { computed, defineComponent, ref, PropType, watchEffect } from 'vue'
import { useStore } from '@/utils/composables'

import { MyndshftPanel } from '@myndshft/panel'
import ModifiedbyTooltip from '@/components/worklist/shared/modifiedby-tooltip.vue'
import FaxCreate from './create.vue'

import { Alert, toast } from '@/dependencies/sweet-alert'
import { useFormatting } from '@/dependencies/formatting'

import { deletePayerItemError } from '@/utils/message'
import { FaxPriority } from '@myndshft/types-mc-api'
import { IContactPayerFax, IPayerPlan } from '@server/api/models/fe/payer'
import { ContactMethodType } from '@server/api/models/fe/shared'

interface IHeader {
	text: string
	value: string
	width?: string
}

export default defineComponent({
	components: {
		MyndshftPanel,
		[FaxCreate.name]: FaxCreate,
		[ModifiedbyTooltip.name]: ModifiedbyTooltip,
	},
	props: {
		payerPlans: Array as PropType<IPayerPlan[]>,
		createFax: { type: Function, required: true },
		updateFax: { type: Function, required: true },
		deleteFax: { type: Function, required: true },
		isSaving: {
			type: Boolean,
			default: false,
		},
		resetPayer: { type: Function, required: true },
	},
	setup(props) {
		const store = useStore()
		const state = ref(store.state.payer)
		const faxes = ref(state.value.contactMethods.faxes)

		watchEffect(() => {
			faxes.value = store.state.payer.contactMethods.faxes
		})

		const headers: IHeader[] = [
			{ text: 'Name', value: 'name' },
			{ text: 'Number', value: 'number' },
			{ text: 'Priority', value: 'priority' },
			{ text: 'Plan', value: 'plan.name' },
			{ text: '', value: 'save', width: '75px' },
		]
		const staticHeaders: IHeader[] = [
			{ text: 'Name', value: 'name' },
			{ text: 'Number', value: 'number' },
			{ text: 'Priority', value: 'priority' },
			{ text: 'Plan', value: 'plan.name' },
		]

		const isLoading = ref(store.state.payer.loading.faxes)
		watchEffect(() => {
			isLoading.value = store.state.payer.loading.faxes
		})

		const save = async (item: IContactPayerFax) => {
			try {
				await props.updateFax(item)
				toast({ type: Alert.SUCCESS, title: 'Successfully updated the fax' })
			} catch (error) {
				toast({ type: Alert.ERROR, title: 'Failed to update fax' })
				props.resetPayer()
			}
		}

		const faxData: IContactPayerFax = {
			id: '',
			name: '',
			number: '',
			plan: {
				id: '',
				name: '',
				uniqueId: '',
			},
			priority: FaxPriority.STANDARD,
			type: ContactMethodType.FAX,
			_meta: {
				lastModified: '',
				lastModifiedBy: '',
			},
		}
		const newFax = ref<IContactPayerFax>({ ...faxData })
		const resetNewFax = () => {
			newFax.value = { ...faxData }
		}

		const createNewFax = async (fax: IContactPayerFax) => {
			try {
				await props.createFax(fax)
				toast({ type: Alert.SUCCESS, title: 'Successfully created the fax' })
				newFax.value = { ...faxData }
			} catch (error) {
				toast({ type: Alert.ERROR, title: 'Failed to create fax' })
			}
		}

		const onDelete = async (fax: IContactPayerFax) => {
			try {
				await props.deleteFax(fax)
				toast({ type: Alert.SUCCESS, title: 'Successfully deleted the fax' })
			} catch (error) {
				if (error) {
					const message = `Failed to delete fax. This contact method has the following associations: ${deletePayerItemError(
						error as string
					)}`
					toast({ type: Alert.ERROR, title: message, timer: 5000 })
				} else {
					toast({ type: Alert.ERROR, title: 'Failed to delete fax' })
				}
			}
		}

		const totalCount = computed(() => {
			return faxes.value.length
		})

		const priorityOptions = [FaxPriority.URGENT, FaxPriority.STANDARD]

		const formatPriority = (priority: string) => {
			const formatted = priority.toLowerCase()
			return formatted.replace(
				formatted.charAt(0),
				formatted.charAt(0).toUpperCase()
			)
		}

		const { toPhoneNumber } = useFormatting()
		const formatPhone = (num: string) => {
			const faxToUpdate = faxes.value.find(
				(fax: IContactPayerFax) => fax.number === num
			)
			const updatedNumber = toPhoneNumber(faxToUpdate.number)
			faxToUpdate.number = updatedNumber
		}

		return {
			faxes,
			headers,
			staticHeaders,
			createNewFax,
			newFax,
			onDelete,
			isLoading,
			resetNewFax,
			totalCount,
			priorityOptions,
			formatPriority,
			formatPhone,
			save,
		}
	},
})
