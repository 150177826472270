var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"requirements-container"},[_c('div',{staticClass:"requirements-header-container"},[_c('span',{staticClass:"requirements-title"},[_vm._v("Prior Authorization Requirements")]),(_vm.hasDefaultRequirements)?_c('span',{staticClass:"default-requirements-label"},[_vm._v("* Prior authorization defaults to the root record if no requirements are matched.")]):_vm._e()]),_c('div',{staticClass:"requirements-content"},[(_vm.item.requirementSetId)?_c('div',[_c('div',{staticClass:"loading-position",class:{ loading: _vm.isLoading }}),_vm._l((_vm.requirements),function(requirement,index){return _c('div',{key:index,staticClass:"requirements"},[_c('div',[_c('em',[_vm._v(_vm._s(requirement.statement))])]),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Requirements")]),_c('th',{staticClass:"text-left"},[_vm._v("Guidelines")]),_c('th',{staticClass:"text-left"},[_vm._v("Contacts")]),_c('th',{staticClass:"text-left"},[_vm._v("Prior Auth")]),_c('th',{staticClass:"text-left"},[_vm._v("PA Configuration")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"requirements-cell"},_vm._l((requirement.fields),function(field,index){return _c('div',{key:index},[_c('span',{staticClass:"field-type"},[_vm._v(" "+_vm._s(_vm.fieldTypeName.get(field.type))+" is ")]),_c('span',{staticClass:"field-parameter"},[_vm._v(" "+_vm._s(_vm.getParameters(field))+" ")])])}),0),_c('td',_vm._l((requirement.outcome.assets),function(asset,index){return _c('div',{key:index},[_c('a',{staticClass:"has-tooltip-arrow",attrs:{"href":asset.url,"target":"_blank","data-tooltip":asset.url}}),_vm._v(" "+_vm._s(asset.name)+" ")])}),0),_c('td',{staticClass:"contacts-slot"},[_c('div',{staticClass:"contact-col"},[(
											_vm.getContactMethods(
												_vm.contactMethodType.PHONE,
												requirement.outcome
											)
										)?_c('fieldset',{staticClass:"contact-display-border"},[_c('legend',[_vm._v("Phone")]),_c('div',{staticClass:"contact-item"},[(
													requirement.outcome.preferredMethod ===
													_vm.contactMethodType.PHONE
												)?_c('fa-icon',{staticClass:"is-preferred",attrs:{"icon":"check-circle"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.getContactMethods( _vm.contactMethodType.PHONE, requirement.outcome ))+" ")],1)]):_vm._e(),(
											_vm.getContactMethods(
												_vm.contactMethodType.FAX,
												requirement.outcome
											).length > 0
										)?_c('fieldset',{staticClass:"contact-display-border"},[_c('legend',[_vm._v("Fax")]),_c('div',{staticClass:"contact-item"},[(
													requirement.outcome.preferredMethod ===
													_vm.contactMethodType.FAX
												)?_c('fa-icon',{staticClass:"is-preferred",attrs:{"icon":"check-circle"}}):_vm._e(),_vm._l((_vm.getContactMethods(
													_vm.contactMethodType.FAX,
													requirement.outcome
												)),function(fax,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(fax.number)+" ")])})],2)]):_vm._e(),(
											_vm.getContactMethods(
												_vm.contactMethodType.PORTAL,
												requirement.outcome
											)
										)?_c('fieldset',{staticClass:"contact-display-border"},[_c('legend',[_vm._v("Portal")]),_c('div',{staticClass:"contact-item"},[(
													requirement.outcome.preferredMethod ===
													_vm.contactMethodType.PORTAL
												)?_c('fa-icon',{staticClass:"is-preferred",attrs:{"icon":"check-circle"}}):_vm._e(),(requirement.outcome.contactMethods.portal)?_c('a',{staticClass:"has-tooltip-arrow contact-item",attrs:{"href":requirement.outcome.contactMethods.portal.url,"target":"_blank","data-tooltip":requirement.outcome.contactMethods.portal.url}},[_vm._v(" "+_vm._s(_vm.getContactMethods( _vm.contactMethodType.PORTAL, requirement.outcome ))+" ")]):_vm._e()],1)]):_vm._e()])]),_c('td',[_vm._v(" "+_vm._s(_vm.authRequirementName.get(requirement.outcome.authRequirement))+" ")]),_c('td',[_c('div',{staticClass:"configuration-col"},[(requirement.outcome.authSubmissionFormId)?_c('fieldset',{staticClass:"configuration-display-border"},[_c('legend',[_vm._v("Fax")]),_c('div',{staticClass:"configuration-item"},[_vm._v(" "+_vm._s(_vm.getFormName( requirement.outcome.authSubmissionFormId ) || requirement.outcome.authSubmissionFormId)+" ")])]):_vm._e(),(requirement.outcome.submissionConfigurationId)?_c('fieldset',{staticClass:"configuration-display-border"},[_c('legend',[_vm._v("Submission")]),_c('div',{staticClass:"configuration-item"},[_vm._v(" "+_vm._s(_vm.getSubmissionConfigurationName( requirement.outcome.submissionConfigurationId ) || requirement.outcome.submissionConfigurationId)+" ")])]):_vm._e()])])])])])],1)})],2):_c('div',[_vm._v("There are no additional requirements")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }