
import { computed, defineComponent, ref, watchEffect, PropType } from 'vue'
import { useStore } from '@/utils/composables'

import { MyndshftPanel } from '@myndshft/panel'
import ModifiedbyTooltip from '@/components/worklist/shared/modifiedby-tooltip.vue'
import PhoneCreate from './create.vue'

import { Alert, toast } from '@/dependencies/sweet-alert'

import { deletePayerItemError } from '@/utils/message'
import { useFormatting } from '@/dependencies/formatting'
import { IContactPayerPhone, IPayerPlan } from '@server/api/models/fe/payer'
import { ContactMethodType } from '@server/api/models/fe/shared'

interface IHeader {
	text: string
	value: string
	width?: string
}

export default defineComponent({
	components: {
		MyndshftPanel,
		[PhoneCreate.name]: PhoneCreate,
		[ModifiedbyTooltip.name]: ModifiedbyTooltip,
	},
	props: {
		payerPlans: Array as PropType<IPayerPlan[]>,
		createPhone: { type: Function, required: true },
		updatePhone: { type: Function, required: true },
		deletePhone: { type: Function, required: true },
		isSaving: {
			type: Boolean,
			default: false,
		},
		resetPayer: { type: Function, required: true },
	},
	setup(props) {
		const store = useStore()
		const state = ref(store.state.payer)
		const phones = ref(state.value.contactMethods.phones)

		watchEffect(() => {
			phones.value = store.state.payer.contactMethods.phones
		})

		const headers: IHeader[] = [
			{ text: 'Name', value: 'name' },
			{ text: 'Number', value: 'number' },
			{ text: 'Plan', value: 'plan.name' },
			{ text: '', value: 'save', width: '75px' },
		]
		const staticHeaders: IHeader[] = [
			{ text: 'Name', value: 'name' },
			{ text: 'Number', value: 'number' },
			{ text: 'Plan', value: 'plan.name' },
		]

		const isLoading = ref(store.state.payer.loading.phonees)
		watchEffect(() => {
			isLoading.value = store.state.payer.loading.phonees
		})

		const save = async (item: IContactPayerPhone) => {
			try {
				await props.updatePhone(item)
				toast({ type: Alert.SUCCESS, title: 'Successfully updated the phone' })
			} catch (error) {
				toast({ type: Alert.ERROR, title: 'Failed to update phone' })
				props.resetPayer()
			}
		}

		const phoneData: IContactPayerPhone = {
			id: '',
			name: '',
			number: '',
			plan: {
				id: '',
				name: '',
				uniqueId: '',
			},
			type: ContactMethodType.PHONE,
			_meta: {
				lastModified: '',
				lastModifiedBy: '',
			},
		}
		const newPhone = ref<IContactPayerPhone>({ ...phoneData })
		const resetNewPhone = () => {
			newPhone.value = { ...phoneData }
		}

		const createNewPhone = async (phone: IContactPayerPhone) => {
			try {
				await props.createPhone(phone)
				toast({ type: Alert.SUCCESS, title: 'Successfully created the phone' })
				newPhone.value = { ...phoneData }
			} catch (error) {
				toast({ type: Alert.ERROR, title: 'Failed to create phone' })
			}
		}

		const onDelete = async (phone: IContactPayerPhone) => {
			try {
				await props.deletePhone(phone)
				toast({ type: Alert.SUCCESS, title: 'Successfully deleted the phone' })
			} catch (error) {
				if (error) {
					const message = `Failed to delete phone. This contact method has the following associations: ${deletePayerItemError(
						error as string
					)}`
					toast({ type: Alert.ERROR, title: message, timer: 5000 })
				} else {
					toast({ type: Alert.ERROR, title: 'Failed to delete phone' })
				}
			}
		}

		const totalCount = computed(() => {
			return phones.value.length
		})

		const { toPhoneNumber } = useFormatting()
		const formatPhone = (num: string) => {
			const phoneToUpdate = phones.value.find(
				(phone: IContactPayerPhone) => phone.number === num
			)
			const updatedNumber = toPhoneNumber(phoneToUpdate.number)
			phoneToUpdate.number = updatedNumber
		}

		return {
			phones,
			headers,
			staticHeaders,
			createNewPhone,
			newPhone,
			onDelete,
			isLoading,
			resetNewPhone,
			totalCount,
			formatPhone,
			save,
		}
	},
})
