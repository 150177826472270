// Creates user friendly message when an error occurs deleting a payer contact method or
// asset from a payer when it is being used on a worklist, requirement, or PA record.
export function deletePayerItemError(errorMessage: string): string {
	return errorMessage
		.substring(errorMessage.indexOf('worklist_items'))
		.split(' |')
		.join(',')
		.toUpperCase()
		.replaceAll('_', ' ')
}
