var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c('myndshft-panel',{staticClass:"ms-information"},[_c('h2',{staticClass:"cpt-title"},[_vm._v("Prior Authorization Records")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-four-fifths"},[_c('v-text-field',{attrs:{"label":"Search for a Code","outlined":"","clearable":"","data-public":""},on:{"input":_vm.onSearch},model:{value:(_vm.searchCode),callback:function ($$v) {_vm.searchCode=$$v},expression:"searchCode"}})],1),_c('div',[_c('button',{staticClass:"ms-btn ms-btn-primary ms-btn-new has-tooltip-right",attrs:{"id":"refresh-records-button","data-tooltip":""},on:{"click":_vm.onRefresh}},[_vm._v(" Refresh PA Records ")])])]),_c('div',{staticClass:"count-container"},[_vm._v(" Total Prior Authorization Records: "+_vm._s(_vm.records.total)+" ")]),_c('v-data-table',{ref:"recordsTable",staticClass:"procedure-code-table",class:{
				'page-loading': _vm.pageLoading,
			},attrs:{"server-items-length":_vm.records.total,"headers":_vm.headers,"items":_vm.records.items,"items-per-page":_vm.perPage,"item-class":_vm.activeItemRow,"page":_vm.tablePage,"height":"500","hide-default-footer":"","fixed-header":"","disable-sort":"","show-expand":""},on:{"update:page":[_vm.onUpdatePage,function($event){_vm.tablePage=$event}]},scopedSlots:_vm._u([{key:`item.outcome.assets`,fn:function({ item }){return _vm._l((item.outcome.assets),function(guideline,index){return _c('div',{key:index},[_c('a',{attrs:{"href":guideline.url,"target":"_blank"}},[_vm._v(" "+_vm._s(guideline.name)+" ")])])})}},{key:`item.contacts`,fn:function({ item }){return [_c('div',{staticClass:"contacts-col"},[(item.outcome.contactMethods.phone)?_c('fieldset',{staticClass:"contact-display-border"},[_c('legend',[_vm._v("Phone")]),_c('tr',[_c('td',[(
										item.outcome.preferredMethod === _vm.contactMethodType.PHONE
									)?_c('fa-icon',{staticClass:"is-preferred",attrs:{"icon":"check-circle"}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.outcome.contactMethods.phone.number)+" ")])],1)])]):_vm._e(),(item.outcome.contactMethods.faxes.length)?_c('fieldset',{staticClass:"contact-display-border"},[_c('legend',[_vm._v("Fax")]),_c('tr',[_c('td',[(
										item.outcome.preferredMethod === _vm.contactMethodType.FAX
									)?_c('fa-icon',{staticClass:"is-preferred",attrs:{"icon":"check-circle"}}):_vm._e(),_vm._l((item.outcome.contactMethods.faxes),function(fax,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(fax.number)+" ")])})],2)])]):_vm._e(),(item.outcome.contactMethods.portal)?_c('fieldset',{staticClass:"contact-display-border"},[_c('legend',[_vm._v("Portal")]),_c('tr',[_c('td',[(
										item.outcome.preferredMethod === _vm.contactMethodType.PORTAL
									)?_c('fa-icon',{staticClass:"is-preferred",attrs:{"icon":"check-circle"}}):_vm._e(),_c('a',{attrs:{"href":item.outcome.contactMethods.portal.url,"target":"_blank"}},[_vm._v(" "+_vm._s(item.outcome.contactMethods.portal.name)+" ")])],1)])]):_vm._e()])]}},{key:`item.outcome.authRequirement`,fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.authRequirementName.get(item.outcome.authRequirement))+" ")])]}},{key:`item.configurations`,fn:function({ item }){return [_c('div',{staticClass:"configurations-col"},[(item.outcome.authSubmissionFormId)?_c('fieldset',{staticClass:"configuration-display-border"},[_c('legend',[_vm._v("Fax")]),_c('tr',[_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm.getFormName(item.outcome.authSubmissionFormId) || item.outcome.authSubmissionFormId)+" ")])])])]):_vm._e(),(item.outcome.submissionConfigurationId)?_c('fieldset',{staticClass:"configuration-display-border"},[_c('legend',[_vm._v("Submission")]),_c('tr',[_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm.getSubmissionConfigurationName( item.outcome?.submissionConfigurationId ) || item.outcome.submissionConfigurationId)+" ")])])])]):_vm._e()])]}},{key:`item.data-table-expand`,fn:function({ item, isExpanded, expand }){return [_c('button',{staticClass:"expand-requirements-button v-icon notranslate v-data-table__expand-icon v-icon--link mdi theme--light",class:{
						'mdi-chevron-up': isExpanded,
						'mdi-chevron-down': !isExpanded,
						'has-requirements': item.requirementSetId,
					},attrs:{"type":"button"},on:{"click":function($event){return expand(!isExpanded)}}})]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('pa-requirement-set',{attrs:{"item":item,"payerId":_vm.payerId,"payerPlanAssociations":_vm.payerPlanAssociations}})],1)]}},{key:"no-data",fn:function(){return [_c('em',[_vm._v("There are no items")])]},proxy:true},{key:`item.modified`,fn:function({ item }){return [_c('modifiedby-tooltip',{staticClass:"modifiedby-icon",attrs:{"name":item.meta.lastModifiedBy,"displayDatetime":new Date(item.meta.lastModified).toLocaleString()}})]}}],null,true)}),_c('v-pagination',{attrs:{"length":_vm.pages,"total-visible":10},model:{value:(_vm.tablePage),callback:function ($$v) {_vm.tablePage=$$v},expression:"tablePage"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }