
import { defineComponent, ref, SetupContext, PropType } from 'vue'

import { useFormatting } from '@/dependencies/formatting'
import { IContactPayerFax, IPayerPlan } from '@server/api/models/fe/payer'
import { FaxPriority } from '@myndshft/types-mc-api'

export default defineComponent({
	name: 'fax-create',
	props: {
		payerPlans: Array as PropType<IPayerPlan[]>,
		fax: { type: Object as PropType<IContactPayerFax>, required: true },
		isSaving: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, context: SetupContext) {
		const observer: any = ref(null)
		const options = FaxPriority
		const priorityOptions = [FaxPriority.STANDARD, FaxPriority.URGENT]

		const priorityName = (priority: FaxPriority) => {
			switch (priority) {
				case options.URGENT:
					return 'Urgent'
				case options.STANDARD:
					return 'Standard'
			}
		}

		const onFaxNumber = (faxNumber: string) => {
			const { toPhoneNumber } = useFormatting()
			props.fax.number = toPhoneNumber(faxNumber)
		}
		const create = (fax: IContactPayerFax) => {
			observer.value.reset()
			context.emit('create', fax)
		}

		const reset = () => {
			observer.value.reset()
			context.emit('reset')
		}

		return {
			create,
			reset,
			priorityOptions,
			priorityName,
			onFaxNumber,
			observer,
		}
	},
})
