
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'
import { useRoute } from '@/utils/composables'

import PaRequirementSet from '@/components/payer/pa-records/pa-requirement-set.vue'
import ModifiedbyTooltip from '@/components/worklist/shared/modifiedby-tooltip.vue'

import {
	IPriorAuthRecord,
	IPriorAuthRecords,
} from '@server/api/models/fe/payer'
import {
	AuthRequirementName,
	ContactMethodType,
	ContactMethodTypeName,
} from '@server/api/models/fe/shared'
import { ActiveStatus } from '@myndshft/types-mc-api'
import { isAlphaNumeric } from '@/utils/helpers'
import { IForm, ISubmissionConfiguration } from '@/models/worklists/outcome'
import {
	fetchForms,
	fetchSubmissionConfigurations,
} from '@/service/worklist.http'
import { fetchPriorAuthRecords } from '@/service/payer.http'

export default defineComponent({
	name: 'pa-records',
	props: {
		payerPlanAssociations: {
			type: Array,
			default: () => [],
		},
	},
	components: {
		[PaRequirementSet.name]: PaRequirementSet,
		ModifiedbyTooltip,
	},
	setup() {
		const route = useRoute()

		const records = ref<IPriorAuthRecords>({
			total: 0,
			totalPages: 0,
			items: [],
		})
		const recordsTable = ref()
		const headers = [
			{ text: 'Plan', value: 'plan', width: '200px' },
			{ text: 'Code', value: 'procedureCode', width: '100px' },
			{ text: 'Category', value: 'procedureCategory', width: '150px' },
			{ text: 'Guidelines', value: 'outcome.assets', width: '150px' },
			{ text: 'Contacts', value: 'contacts', width: '200px' },
			{
				text: 'Prior Auth',
				value: 'outcome.authRequirement',
				width: '150px',
			},
			{
				text: 'PA Configuration',
				value: 'configurations',
				width: '200px',
			},
			{
				value: 'modified',
				sortable: false,
			},
		]
		const authRequirementName = AuthRequirementName
		const contactMethodTypeName = ContactMethodTypeName
		const contactMethodType = ContactMethodType
		const page = ref(0)
		const perPage = ref(50)
		const pages = ref(Math.ceil(records.value.total / perPage.value))
		const tablePage = ref(1)
		const pageLoading = ref(true)
		const searchCode = ref('')
		const payerId = ref(route.params.id)
		const formIds = computed<string[]>(() =>
			records.value?.items?.map(
				(item: any) => item?.outcome?.authSubmissionFormId || ''
			)
		)

		const submissionConfigurationIds = computed<string[]>(() =>
			records.value?.items?.map(
				(item: any) => item?.outcome?.submissionConfigurationId || ''
			)
		)
		const formOptions = ref<IForm[]>([])
		const configurationOptions = ref<ISubmissionConfiguration[]>([])

		const getRecords = () => {
			pageLoading.value = true
			if (searchCode.value && !isAlphaNumeric(searchCode.value)) {
				toast({
					type: Alert.ERROR,
					title: 'Invalid input. Only alphanumeric searches are allowed.',
				})
			} else {
				fetchPriorAuthRecords(
					payerId.value,
					page.value,
					perPage.value,
					searchCode.value || ''
				)
					.then(response => {
						records.value = response
					})
					.catch(error => {
						console.warn(error)
						toast({
							type: Alert.ERROR,
							title: 'Failed to retrieve payer prior auth records',
						})
					})
					.finally(() => {
						pages.value = Math.ceil(records.value.total / perPage.value)
						pageLoading.value = false
					})
			}
		}

		const onSearch = () => {
			tablePage.value = 1
			getRecords()
		}

		const onUpdatePage = (dataTablePage: number) => {
			// Service page is 0-based; data table is 1-based
			page.value = dataTablePage - 1

			scrollToTop()
			getRecords()
		}

		const scrollToTop = () => {
			// If the data table exists, scroll to the top
			if (recordsTable.value) {
				const table = recordsTable.value as any
				table.$vuetify.goTo('.v-data-table tbody tr', {
					offset: 0,
					container: '.v-data-table__wrapper',
				})
			}
		}

		const onRefresh = () => {
			getRecords()
		}

		const activeItemRow = (item: IPriorAuthRecord) => {
			return item.active !== ActiveStatus.ACTIVE
				? 'item-inactive'
				: 'item-active'
		}

		const getFormName = (id: string): string => {
			return formOptions.value?.find(opt => opt.id === id)?.name || ''
		}

		const getSubmissionConfigurationName = (id: string): string => {
			return configurationOptions.value?.find(opt => opt.id === id)?.name || ''
		}

		watch(formIds, ids => {
			const validIds = ids.filter(id => !!id)
			if (validIds.length > 0) {
				fetchForms(null, validIds).then(res => {
					formOptions.value = res
				})
			}
		})

		watch(submissionConfigurationIds, ids => {
			const validIds = ids.filter((id: string | null) => !!id)
			if (validIds.length > 0) {
				fetchSubmissionConfigurations(null, validIds).then(res => {
					configurationOptions.value = res
				})
			}
		})

		onMounted(() => {
			getRecords()
		})

		return {
			recordsTable,
			records,
			headers,
			authRequirementName,
			contactMethodType,
			contactMethodTypeName,
			pageLoading,
			searchCode,
			tablePage,
			perPage,
			pages,
			payerId,

			getFormName,
			getSubmissionConfigurationName,
			activeItemRow,
			onUpdatePage,
			onRefresh,
			onSearch,
		}
	},
})
